@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
    .perspective-\[1000px\] {
      perspective: 1000px;
    }
  
    .transform-style-3d {
      transform-style: preserve-3d;
    }
  }

html {
scroll-behavior: smooth;
}